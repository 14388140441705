import React from 'react'
import "../styles/home.css"
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import img1 from "../assets/kaba_pic.jpg"
import img2 from "../assets/haj_pic.jpg"
import umrah2 from "../assets/Umrah.webp"
import Packages from './Packages';
import { Navigate, useNavigate } from 'react-router-dom';

import img3 from "../assets/gumbad_pic.jpg"

const Home = () => {
    const MyCarousel = () => (
        <Carousel className='carousel' autoPlay infiniteLoop interval={3000} showArrows={false} showThumbs={false} showStatus={false}>

            <div id='carousel_div'>
                <img src={img1} alt="" />
            </div>

            <div id='carousel_div'>
                <img src={img2} alt="" />
            </div>

            <div id='carousel_div'>
                <img src={img3} alt="" />
            </div>
        </Carousel>)


const Navigate = useNavigate();

  const handleClick = () => {
    
    Navigate('/packages')};
    return (
        <div className="container">
            <section className='carousel-section'>
               <MyCarousel /> </section>
                <div className='content-section'>
                    <h2>Looking For A Pleasant Hajj? Check Out Our Hajj Packages 2024 Now!</h2>
                    <button id='checkforpackages'onClick={handleClick}>Check Our Packages</button>
                </div>
                <section className='kabaa-txt'>
                <div className="umrah-wrapper">
                    <div className='umrah-image'>
                        <img src={umrah2} alt="" />
                    </div>
                    <div className='umrah-para'>
                        <div className='umrah-para-inner'>
                            <h2>Best Hajj Travel Agency 2024</h2>
                            <p>Welcome to AL ZIYARAH! As we all know how important Hajj is for us. It’s a journey of a lifetime, and we are here to make it easy and special for you. We’ve planned everything carefully so you can have a great time and feel close to Allah SWT.</p>
                            <p>Our services include everything you need for your trip, like visas, places to stay, food, and guided tours of important places. Our team knows all about Hajj and will help you every step of the way.</p>
                            <p>Come with us on this amazing journey and make memories that will last forever!</p>
                            <p>Ready to go on a journey? Contact us now to book our hajj services and let’s not wait anymore!</p>
                        </div>
                    </div>
                </div>
                </section>
                
                <div className="packages">
                    <Packages />
                </div>
          
            
            
        </div>
    )
}



export default Home