import React from "react";
import { motion } from "framer-motion";
import aeroplane from "../assets/aeroplane.png";
import "../styles/contact.scss"
import emailjs from 'emailjs-com';

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace these values with your actual EmailJS service, template, and user IDs
    const serviceID = 'service_kyu8hpz';
    const templateID = 'template_fm6kdgm';
    const userID = 'ZCrRkN0wf1rbzlxtY';

    emailjs.sendForm(serviceID, templateID, e.target, userID)
      .then((result) => {
        console.log('Email sent successfully:', result.text);
      }, (error) => {
        console.error('Error sending email:', error.text);
      });}
  return (
    <section className="contact">
      <motion.form onSubmit={handleSubmit}
        initial={{
          x: "-100vw",
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        transition={{ delay: 0.2 }}
      >
        <h2>Contact Us</h2>
        <input type="text" placeholder="Name" name="name"/>
        <input type="email" placeholder="Email" name="user_email"/>

        <textarea placeholder="Message..." cols="30" rows="10" name="message"></textarea>

        <button type="submit">Send</button>
      </motion.form>

      <motion.div
        className="formBorder"
        initial={{
          x: "100vw",
          opacity: 0,
        }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        transition={{ delay: 0.2 }}
      >
        <motion.div
          initial={{
            y: "-100vh",
            x: "50%",
            opacity: 0,
          }}
          animate={{
            x: "50%",
            y: "-50%",
            opacity: 1,
          }}
          transition={{
            delay: 1,
          }}
        >
          <img src={aeroplane} alt="aeroplane" />
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Contact;