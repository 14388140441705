import { Route, BrowserRouter as Router, Routes  } from 'react-router-dom';
import Header from './components/Headers';
import Home from './components/Home';
import Footer from './components/Footer';
import Packages from './components/Packages';
import Registration from './components/Registration';

// import './styles/package.css'
import Contact from './components/Contact';
import About from './components/About';
import Gallery from './components/Gallery';
function App() {
  return (
    <div className="App">
      <Router>
        <Header/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/packages' element={<Packages/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/gallery' element={<Gallery/>}/>
          <Route path='/registration' element={<Registration/>}/>

          </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
