import React from "react";
import { Link } from "react-router-dom";
import { RiFindReplaceLine } from "react-icons/ri";
import me from "../assets/holy kaba.png";
import "../styles/about.scss";

const About = () => {
  return (
    <section className="about">
      <main>
        <h1>About Us</h1>

        <article>
          <h4> collab b/w Al Ziyarah Tours & Travels Ltd and Pak Ban </h4>
          <p>
            After considerable thought, we have picked Pak Ban as our partner
            due to their shared passion to quality and commitment to supporting
            pilgri ms on their sacred journey. At Al Ziyarah Tours & Travels
            Ltd, we take enormous pride in delivering customized and honest
            service while upholding the highest standard s of quality in all our
            services. Our decision to join up with Pak Ban shows our steadfast
            commitment to provide you with a Hajj experience that beyond your
            expectations. Transparency sits at the heart of our operations, and
            we are determined to performing all transactions with total openness
            and integrity. We sincerely think that transparency cultivates
            confidence and fortifies our ties with our cherished clientele. We
            are happy to announce the finalization of our 2024 Hajj Programs and
            anxiously await sharing the specifics with you. We encourage you to
            share the news among your fellow pilgrims and accompany us on this
            journey to remain updated on the newest develop ments and
            information surrounding our Hajj Programs.
          </p>

          <p>
            Explore the various type of food and burgers. Click below to see the
            menu
          </p>

          <Link to="/">
            <RiFindReplaceLine />
          </Link>
        </article>

        <div>
          <h2>AL ZIYARAH TOURS & TRAVELS LTD.</h2>
          <article>
            <div>
              <img src={me} alt="Founder" />
              <h3>AL ZIYARAH TRAVELS</h3>
            </div>

            <p>
              Our Commitment to Excellence: Customized Experience: We are
              committed to going the additional mile to customize your dream
              experience according to your preferences and flexibility.
            </p>
            <p>
              Exclusive Selection: We diligently design the bestsuited travel
              and vacation packages for you, ensuring every aspect is
              thoughtfully compiled to fulfill your demands.
            </p>
            <p>
              Abundant Choices: Choose from a profusion of vacation alternatives
              and intriguing events, each giving a distinct and enlightening
              experience.
            </p>
            <p>
              Expert Guidance: Rest assured, you will be guided by our team of
              seasoned specialists who will guarantee your experience is
              seamless and unfo rgettable.
            </p>
          </article>
        </div>
      </main>
    </section>
  );
};

export default About;
