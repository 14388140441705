import React from 'react';
import '../styles/registration.css';
import emailjs from 'emailjs-com';
import { useState } from 'react';
import '../styles/regpopup.css'

const Popup = ({ message,onClose }) => {
    const handleClose = () => {
        onClose && typeof onClose === 'function' && onClose();
      };
    return (
        <div className="popup">
        <div className="popup-content">
          <span className="close-btn" onClick={handleClose}>&times;</span>
          <p>{message}</p>
        </div>
      </div>
    );
  };
const Registration = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        passportissuingcountry: '',
        passport: '',
        passportexpirydate: '',
        gendermale: '',
        genderfemale: '',
        dateofbirth: '',
        applicantage: '',
        mahramcontactname: '',
        mahramrelationship: '',
        email: '',
        address: '',
        city: '',
        province: '',
        postalcode: '',
        phonenumber: '', 
        package:'',
        documents: '', 
        
      });

     const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value, 
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedPackage = formData.package;

    const serviceID = 'service_58gnpk3'; 
    const templateID = 'template_dje3bi7'; 
    const userID = 'ZCrRkN0wf1rbzlxtY'; 

    const templateParams = {
      ...formData,
      files: formData.documents.name, 
      package: selectedPackage
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setShowPopup(true);
      })
      .catch((error) => {
        console.error('Error sending email:', error.text);
      });
  };
  const closePopup = () => {
    setShowPopup(false);
  };
    return (
        <section className='reg_section'>
            <div className="reg_container">
                <div className="upper">
                    <h1>Registration Form</h1>
                    <p>Registration Process: (First Come First Served)</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='info'>
                        <p>First & others Names (as written on the passport)</p>
                        <input type="text" required onChange={handleChange}  name='firstname' />
                    </div>
                    <div className='info'>
                        <p>Last Name (as written on the passport)</p>
                        <input type="text" required  onChange={handleChange}  name='lastname'/>
                    </div>
                    <div className='info'>
                        <p>Passport Issuing Country</p>
                        <input type="text" required onChange={handleChange}  name='passportissuingcountry' />
                    </div>
                    <div className='info'>
                        <p>Passport #</p>
                        <input type="text" required onChange={handleChange}  name='passport' />
                    </div>
                    <div className='info'>
                        <p>Passport Expiry Date</p>
                        <input type="text" required onChange={handleChange}  name='passportexpirydate' />
                    </div>
                    <div className='info'>
                        <p>Gender</p>
                        <input type="text" required onChange={handleChange}  name='gender' />
                    </div>

                    
                    <div className='info'>
                        <p>Date of Birth</p>
                        <input type="date" name="dateofbirth" max={new Date().toISOString().split('T')[0]} required onChange={handleChange}  />
                    </div>
                    <div className='info'>
                        <p>Applicant age (at time of travel)</p>
                        <input type="text" required  onChange={handleChange}  name='applicantage'/>
                    </div>
                    <div className='info'>
                        <p>Note:</p>
                        <h2>(Male/female applicant less than 18 years old – at the time of application- must have a Mahram)</h2>
                    </div>
                    <div className='info'>
                        <p>Mahram Contact Name (if any)</p>
                        <input type="text" required onChange={handleChange}  name='mahramcontactname'/>
                    </div>
                    <div className='info'>
                        <p>Mahram Relationship (if any)</p>
                        <input type="text" required onChange={handleChange}  name='mahramrelationship'/>
                    </div>
                    <div className='info'>
                        <p>Email:</p>
                        <input type="email" required onChange={handleChange}  name='email'/>
                    </div>
                    <div className='info'>
                        <p>Address</p>
                        <input type="text" required onChange={handleChange}  name='address' />
                    </div>
                    <div className='info'>
                        <p>City</p>
                        <input type="text" required  onChange={handleChange}  name='city'/>
                    </div>
                    <div className='info'>
                        <p>Province</p>
                        <input type="text" required onChange={handleChange}  name='province'/>
                    </div>
                    <div className='info'>
                        <p>Postal code</p>
                        <input type="text" required onChange={handleChange}  name='postalcode'/>
                    </div>
                    <div className='info'>
                        <p>Phone</p>
                        <input type='number' required onChange={handleChange}  name='phonenumber'/>
                    </div>
                   
                    <div className='info'>
                        <p>Select Package</p>
                        <select  name='package' onChange={handleChange} value={formData.package}>
                            <option value="" disabled>Choose package</option>
                            <option value="Platinum">Platinum</option>
                            <option value="Diamond">Diamond</option>
                            <option value="Gold">Gold</option>
                        </select>
                    </div>
                    
                    <div className='info'>
                        <p>Upload Documents (Less than 50kb)</p>
                        <input type="file" name="documents" accept=".pdf,.doc,.docx"   onChange={handleChange}  />
                    </div>
                    <button type='submit'>Submit</button>
                </form>
                {showPopup && <Popup message="Email sent successfully!" onClose={closePopup} />}
            </div>
        </section>
    );
};

export default Registration;


