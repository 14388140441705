import React from 'react'
import '../styles/package.css'
// import '../styles/home.css'
import Card from './Card'
import imgplat from '../assets/platinum.jpeg'
import imgdiamond from '../assets/diamond.jpeg'
import kabaa from "../assets/kabaa.webp"
import imggold from '../assets/gold.jpeg'


// import { motion } from "framer-motion";

const Packages = () => {
    return (
        <section className='section-main'>
            <div className='h1'>
                <h1>MOST POPULAR HAJJ PACKAGES</h1>

            </div>
            <div className="cards" >
                <Card h1="Platinum " imgSrc={imgplat} delay={1} />
                <Card h1="Diamond " imgSrc={imgdiamond} delay={2} />
                <Card h1="GOld " imgSrc={imggold} delay={3} />
            </div>
            <div className='h1'>
                <h1>Embark on Your Adventure: Explore the World with Our Tailored Travel Experiences</h1>

            </div>

            <div className="hajj-wrapper">
                <div className='hajj-image'>
                    <img src={kabaa} alt="" />
                </div>
                <div className='hajj-para'>
                    <div className='hajj-para-inner'>
                        <h2>Our Commitment to Excellence</h2>
                        <p>Tailored Experiences: Experience the journey of a lifetime with our customized Hajj 2024 packages. We are committed to crafting an unforgettable experience tailored to your preferences and flexibility.</p>
                        <p>Carefully Curated Selection: Dive into our exclusive selection of travel and vacation packages, meticulously crafted to cater to your every need. From luxurious accommodations to immersive cultural experiences, we ensure that every aspect of your journey is thoughtfully compiled to exceed your expectations.</p>
                        <p>A World of Options: With Al Ziyarah Travel Agency, the world is yours to explore. Choose from a diverse range of vacation options and captivating events, each offering a unique and enriching experience. Whether you seek spiritual enlightenment or cultural immersion, we have the perfect itinerary for you.

                            Guidance from Experts</p>
                        <p>Guidance from Experts: Leave the details to us and embark on your journey with confidence. Our team of seasoned experts is dedicated to providing you with expert guidance every step of the way. From planning logistics to navigating cultural nuances, rest assured that your experience with us will be seamless and unforgettable.</p>
                    </div>
                </div>
            </div>

        </section>

    )
}

export default Packages