import image from "../assets/holy kaba.png"
import React, { useState } from "react";
import "../styles/header.css";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

import { NavLink } from "react-router-dom";

const Header = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const toggleMenu = () => {
    setShowMediaIcons(!showMediaIcons);
  };
  return (
    <>
      <nav className="main-nav">
        <img src={image} alt="" />
        {/* 1st logo part  */}
        <div className="logo">
          <h2>
            <span>A</span>L
            <span> Z</span>IYARAH
          </h2>
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
          <ul>
            <li>
              <NavLink to="/" onClick={toggleMenu}>Home</NavLink>
            </li>
            <li>
              <NavLink to="/about" onClick={toggleMenu}>about</NavLink>
            </li>
            
            <li>
              <NavLink to="/packages" onClick={toggleMenu}>packages</NavLink>
            </li>
            <li>
              <NavLink to="/contact" onClick={toggleMenu}>contact</NavLink>
            </li>
            <li>
              <NavLink to="/gallery" onClick={toggleMenu}>gallery</NavLink>
            </li>
            <li>
              <NavLink to="/registration" onClick={toggleMenu}>registration</NavLink>
            </li>
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">
          <ul className="social-media-desktop">
            <li>
              <a
                href="https://www.facebook.com/"
                target="blank">
                <FaFacebookSquare className="facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/"
                target="blank">
                <FaInstagramSquare className="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/"
                target="blank">
                <FaYoutubeSquare className="youtube" />
              </a>
            </li>
          </ul>

          {/* hamburget menu start  */}
          <div className="hamburger-menu">
          <a href="#" onClick={toggleMenu}>
              <GiHamburgerMenu id="hamburger"/>
            </a>
          </div>
        </div>
      </nav>

      
    </>
  );
};

export default Header;