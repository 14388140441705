import React from "react";
import { AiFillInstagram, AiFillYoutube, AiFillFacebook } from "react-icons/ai";
import "../styles/footer.scss";
const Footer = () => {
  return (
    <footer>
      <div>
        <h2>AL ZIYARAH TOURS & TRAVEL LTD</h2>

        <p>
          Al Ziyarah Tours & Travels Ltd, we take immense pride in delivering
          personalized and transparent service while upholding the highest
          standards of quality in all our offerings.
        </p>
        <br />

        <em>We give attention to genuine feedback.</em>

        <strong>All right received @al-ziyarah-travel-ltd</strong>
      </div>

      <aside>
        <h4>Follow Us</h4>

        <a href="https://youtube.com/">
          <AiFillYoutube />
        </a>
        <a href="https://instagram.com/">
          <AiFillInstagram />
        </a>
        <a href="https://facebook.com/">
          <AiFillFacebook />
        </a>
      </aside>
    </footer>
  );
};

export default Footer;
