import React from 'react'
import '../styles/package.css'
import {useNavigate } from 'react-router-dom';
import { useState } from 'react';

const Card = ({h1,imgSrc}) => {
  const Navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleClick = () => {
    
    Navigate('/registration');
  };
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setModalOpen(false);
  };
  return (
  
    <section className='section-of-card'>
        <h1>{h1}</h1>
        <img src={imgSrc} alt="" onClick={() => handleImageClick(imgSrc)} />
       <button onClick={handleClick}>Get Started</button>
       {modalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <span className="close-btn-popup" onClick={closeModal}>
              &times;
            </span>
            <img id='image' src={selectedImage} alt="Selected" />
          </div>
        </div>
      )}
    </section>
    
    
  
  )
}

export default Card