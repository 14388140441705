import React from "react";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";
import img1 from "../hajj gallery/c1.jpg";
import img2 from "../hajj gallery/c2.jpg";
import img3 from "../hajj gallery/c3.jpg";
import img4 from "../hajj gallery/c4.jpg";
import img5 from "../hajj gallery/c5.jpg";
import img6 from "../hajj gallery/c6.jpg";
import img7 from "../hajj gallery/c7.jpg";
import img8 from "../hajj gallery/c8.jpg";
import img9 from "../hajj gallery/c9.jpg";
import img10 from "../hajj gallery/c10.jpg";
import img11 from "../hajj gallery/c11.jpg";
import img12 from "../hajj gallery/c12.jpg";
import img13 from "../hajj gallery/c13.jpg";
import img14 from "../hajj gallery/c14.jpg";
import img15 from "../hajj gallery/c15.jpg";
import img16 from "../hajj gallery/c16.jpg";
import img17 from "../hajj gallery/c17.jpg";
import img18 from "../hajj gallery/c18.jpg";
import img19 from "../hajj gallery/c19.jpg";

const Gallery = () => {
  const Images = [
    {
      id: 1,
      img: img1,
    },
    {
      id: 2,
      img: img2,
    },
    {
      id: 3,
      img: img3,
    },
    {
      id: 4,
      img: img4,
    },
    {
      id: 5,
      img: img5,
    },
    {
      id: 6,
      img: img6,
    },
    {
      id: 7,
      img: img7,
    },
    {
      id: 8,
      img: img8,
    },
    {
      id: 9,
      img: img9,
    },
    {
      id: 10,
      img: img10,
    },
    {
      id: 11,
      img: img11,
    },
    {
      id: 12,
      img: img12,
    },
    {
      id: 13,
      img: img13,
    },
    {
      id: 14,
      img: img14,
    },
    {
      id: 15,
      img: img15,
    },
    {
      id: 16,
      img: img16,
    },
    {
      id: 17,
      img: img17,
    },
    {
      id: 18,
      img: img18,
    },
    {
      id: 19,
      img: img19,
    },
  ];

  const MyCarousel = () => (
    <Carousel
      className="carouselforgallery"
      showArrows={false}
      showThumbs={false}
      showStatus={false}
    >
      

      {Images?.map((item)=>{
        return (
          <div className="carouseldiv" key={item.id}>
            <img src={item.img} alt="" />
          </div>
        )
       
      })}
    </Carousel>
  );
  return (
    <div className="maincarousel">
      <MyCarousel />
    </div>
  );
};

export default Gallery;
